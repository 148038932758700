:root {
  /* colors */
  --bg-color: white;
  --fg-color: black;

  --primary: #00838f;
  --primary-light: #4fb3bf;
  --primary-dark: #005662;

  --secondary: #fb8c00;
  --secondary-light: #ffbd45;
  --secondary-dark: #c25e00;

  --text-on-primary: white;
  --text-on-secondary: black;

  /* fonts */
  --font-h16: 400 16px/19px "Roboto", sans-serif;
}