.caption {
  padding: 0 16px;
}

.slide {
  position: relative;
}

.slide:before {
  content: "";
  display: block;  
  padding-bottom: 62%;
}

.image {
  max-height: 120%;
  max-width: 120%;  
  min-height: 100%;
  min-width: 100%;  
  left: 50%;  
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}