.container {
  display: flex;
  overflow-x: auto;
  padding: 0 16px;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.item {
  display: block;
  overflow: hidden;
  width: calc(100vw - 32px);
}

.item:not(:last-child) {
  margin-right: 16px;
}